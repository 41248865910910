.cardStyle {
  border: none;
  box-shadow: #8080806e 0px 2px 3px 1px;
  padding: 30px 48px; }

.pageTitle {
  font-family: Gotham;
  font-size: 32px;
  font-weight: bold;
  line-height: 1.25;
  color: #242934; }

.pageSubTitle, .pageSubTitleBold, .pageSubTitleBold500 {
  font-family: Gotham;
  font-size: 18px;
  line-height: 1.33;
  color: #000000;
  font-weight: 400; }

.pageSubTitleBold {
  font-weight: bold; }

.pageSubTitleBold500 {
  font-weight: 500; }

.cardHeader {
  padding: 27px 0px; }

#subjects-list-label,
#subjects-list-sub-label {
  font-family: Gotham;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5;
  color: #000000;
  margin-top: 20px; }

#subjects-list-sub-label {
  font-weight: 400;
  line-height: 1;
  margin-top: 0px; }

#subjects-list {
  border-radius: 4px;
  border: solid 3px #808080;
  font-family: Gotham;
  font-size: 16px; }

#send-contactus {
  margin-top: 20px; }

.contact-us-reason {
  border-style: none; }

.contact-us-submit-button, .contactUsConfDashboard {
  width: 100%;
  height: 46px;
  border-radius: 4px;
  font-family: Gotham;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  background-color: #a71930; }

.contactUsConfDashboard {
  padding: 15px;
  text-decoration: none; }
  .contactUsConfDashboard:focus, .contactUsConfDashboard:hover, .contactUsConfDashboard:visited, .contactUsConfDashboard:link, .contactUsConfDashboard:active {
    text-decoration: none;
    color: #ffffff; }
