.text-input-container {
  display: flex;
  flex-direction: column;
  margin-top: 20px; }
  .text-input-container label {
    font-family: Gotham;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.5;
    color: #000000; }
  .text-input-container input {
    border-radius: 4px;
    border: solid 3px #808080;
    background-color: #ffffff;
    padding: 9px 19px 4px 19px;
    font-family: Gotham;
    font-size: 16px;
    line-height: 1.5; }

.read-only {
  background-color: lightgray !important; }
