
.editing-jobstatus-class{
  background-color: aquamarine;
}

.invalid-jobstatus-class {
  background-color: orangered;
}

.editing-jobsname-class {
  background-color: blueviolet;
}

.invalid-jobsname-class {
  background-color: orange;
}

.sorting {
  color: red;
}

.sort-desc {
  color: chartreuse;
}

.sort-asc {
  color: orange;
}
