.text-area-container {
  display: flex;
  flex-direction: column;
  margin-top: 20px; }
  .text-area-container #label-text {
    font-family: Gotham;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.5;
    color: #000000; }
  .text-area-container #label-char-limit {
    color: #7E6868;
    font-family: Gotham;
    font-size: 12px;
    font-weight: 300;
    line-height: 24px; }
  .text-area-container .text-right-align {
    text-align: right; }
  .text-area-container textarea {
    width: 100%;
    border-radius: 4px;
    border: solid 3px #808080;
    background-color: #ffffff;
    padding: 9px 19px 4px 19px;
    font-family: Gotham;
    font-size: 16px;
    line-height: 1.5;
    min-height: 200px; }
